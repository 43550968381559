import type { LoggedInUserData } from '@wix/identity-fed-common';

export interface UserAccountsDataByEmailResponse {
  accountsData?: AccountData[];
  loggedInUserData?: LoggedInUserData;
}

export interface AuthAdditionalRegisterData {
  inviteToken?: string;
  userToken?: string;
  referralInfo?: string;
  sendEmail?: boolean;
  color?: string;
  studio?: boolean;
}

export interface AuthRequest extends RecaptchaParams {
  email: string;
  password: string;
  rememberMe?: boolean;
  invisibleRecaptcha?: string;
  color?: string;
}

export interface SignupAuthRequest
  extends AuthRequest,
    AuthAdditionalRegisterData {}

export interface ApiResponseData {
  errorCode: string | number;
  errorDescription: string;
  success: boolean;
}

export interface isTokenValidResponse extends ApiResponseData {
  payload?: {
    errorCode?: string | number;
    isValid: boolean;
  };
}

export interface TwoFactorAuthPayload {
  twoFAToken: string;
  usingTwoFAFallback?: boolean;
  twoFAHint: string;
  currentTwoFAMethod?: TwoFactorAuthMethod;
  enabledTwoFAMethods?: TwoFactorAuthMethod[];
}

export interface LoginResponseData extends ApiResponseData {
  uaToken?: string;
  authMethod?: string;
  payload?: Partial<TwoFactorAuthPayload>;
}

export interface VerifyCodeAndLoginRequest {
  stateToken?: string;
  twoFAToken: string;
  code?: string;
  twoFAMethod: string;
  rememberMe?: boolean;
}

export interface VerifyRecoveryCodeResponse extends ApiResponseData {
  payload: { emailToken: string };
  success: boolean;
}

export interface VerifyRecoveryCodeRequest {
  email: string;
  code: number;
  parentAccountId?: string;
  accountRecoveryToken?: string;
}

export interface InitiateStepUpAuthRequest {
  actionTag: string;
  phoneDeliveryMethod?: TwoFactorAuthPhoneDeliveryMethod;
}

export interface ExecuteStepUpAuthRequest {
  actionTag: string;
  code: string;
  verificationId?: string;
  state?: string;
}

export const TwoFactorAuthMethods = {
  TOTP: 'TOTP',
  Phone: 'Phone',
  Email: 'Email',
  Push: 'Push',
};

export const TwoFactorAuthPhoneDeliveryMethods = {
  SMS: 'SMS',
  VOICE_CALL: 'VOICE_CALL',
};

export type TwoFactorAuthMethod =
  | typeof TwoFactorAuthMethods.TOTP
  | typeof TwoFactorAuthMethods.Phone
  | typeof TwoFactorAuthMethods.Email
  | typeof TwoFactorAuthMethods.Push;

export type TwoFactorAuthPhoneDeliveryMethod =
  | typeof TwoFactorAuthPhoneDeliveryMethods.SMS
  | typeof TwoFactorAuthPhoneDeliveryMethods.VOICE_CALL;

export interface LoginToWixFacebookParams {
  access_token: string;
  user_id: string;
}

export interface FacebookAuthResult {
  userID: string;
  accessToken: string;
}

export interface LoginToWixGoogleParams {
  code?: string;
  id_token: string;
}
export interface GoogleAuthResult {
  credential?: string; // This field is the ID token as a base64-encoded JWT
  select_by?: string; // https://developers.google.com/identity/gsi/web/reference/js-reference#select_by
  state?: string; // only in onetap, will be removed and replaced by select_by after onetap on home page will upgrade
  id_token?: string; // only in onetap, will be removed and replaced by credential after onetap on home page will upgrade
  error?: string; // only in case of an error
}

export interface LoginToWixAppleParams {
  identityToken: string;
  firstName?: string;
  lastName?: string;
  email?: string;
}

export interface AppleAuthResult {
  user: {
    name?: {
      firstName?: string;
      lastName?: string;
    };
    email?: string;
  };
  authorization: {
    id_token: string;
  };
}

export interface AccountData {
  parentAccountId?: string;
  accountSsoSettings?: {
    isSsoMandatory?: boolean;
    accountId: string;
    ssoId: string;
    ssoUrl: string;
  };
  userAuthenticatorsData?: {
    hasPassword?: boolean;
    socialConnectionsProviders?: string[];
  };
  isBlocked?: boolean;
}

export enum Step {
  DeviceSelection,
  Confirmation,
  Successful,
}
interface StepDefinition {
  primaryButtonText?: string;
  primaryButtonAction?: () => void;
  secondaryButtonText?: string;
  secondaryButtonAction?: () => void;
  modalHeight?: number;
}
export type StepDefinitions = {
  [key in Step]: StepDefinition;
};

export type Values<T> = T[keyof T];
