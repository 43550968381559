import { NEW_POST_LOGIN_ROUTES, OLD_POST_LOGIN_ROUTES } from '../routes';

export const WIX_EMPLOYEES_EMAIL_DOMAIN = 'wix.com';
export const ACCOUNT_SETTINGS_URL =
  'https://manage.wix.com/account/account-settings';
export const LOST_ACCESS_TO_PHONE_ARTICLE_URL = (locale: string = 'en') =>
  `https://support.wix.com/${locale}/article/unable-to-sign-into-your-wix-account-lost-phone`;
export const LOST_ACCESS_TO_EMAIL_ARTICLE_URL = (locale: string = 'en') =>
  `https://support.wix.com/${locale}/article/unable-to-sign-in-to-your-wix-account-cant-access-email-account`;

export const LOGIN_PAGE_CONTEXT = 'login';
export const SIGNUP_PAGE_CONTEXT = 'signup';
export const GET_USER_ACCOUNTS_CONTEXT = 'GET_USER_ACCOUNTS_CONTEXT';
export const FEDOPS_APP_NAME = 'login';
export const LOGIN_MAIN_PAGE_BASIC_URL = 'https://users.wix.com/signin';
export const ACCOUNT_RECOVERY_URL = 'https://users.wix.com/account-recovery';
export const ACCOUNT_RECOVERY_FORGOT_PASSWORD_URL =
  'https://users.wix.com/account-recovery/forgot-password';
export const WIX_HOME_URL = 'https://www.wix.com/';
export const STUDIO_HOME_URL = 'https://www.wix.com/studio';
export const API_PATH = '/login/api/';
export const USERS_API_PATH = '/_api/wix-html-login-webapp';
export const IS_BLANK_GRAVATAR =
  /(https?:\/\/(.+?\.)?gravatar\.com(\/[A-Za-z0-9\-\._~:\/\?#\[\]@!$&'\(\)\*\+,;\=]*)?)(\?|&)((d=blank)(&?))+/g;
export const AUTH_METHODS_BY_MAIL = {
  LOGIN_PASSWORD: 'LOGIN_PASSWORD',
  SIGNUP_PASSWORD: 'SIGNUP_PASSWORD',
  SINGLE: 'SINGLE',
  MULTI: 'MULTI',
  SOCIAL_ONLY: 'SOCIAL_ONLY',
  WIX_EMPLOYEE: 'WIX_EMPLOYEE',
} as const;
export const RESEND_INDICATION_TTL_TIMER = 30000;
export const CAPTCHA_KEY = 'recaptchaParams.recaptcha_response_field';

export const PARTNERS_API_PATH = '_api/partners-dashboard-server';
export const ACCOUNT_SETTINGS_API_PATH = '_api/account-settings-server';
export const ACCOUNT_SERVER_API_PATH = '_api/account-server/v1/';
export const FETCH_OFFERS_URL =
  '/_api/dealer-offers-serving-service/v1/dealer/serving/offers/list';
export const OWNER_AUTHENTICATOR_PATH = '_api/verification-web/v1/challenges';

export const FORGOT_PASSWORD_LINK = (
  sessionId: string,
  overrideLocale?: string
) => {
  const withLocale = overrideLocale ? `&overrideLocale=${overrideLocale}` : '';
  return `/account-recovery/forgot-password?sessionId=${sessionId}${withLocale}`;
};

export const ConfirmaitonCodeActions = {
  SEND: 'send',
  RESEND: 'resend',
} as const;

export type Values<T> = T[keyof T];

export const POST_LOGIN_OFFERS = {
  CONFIRM_EMAIL: 'confirm-email',
  CONFIRM_DETAILS: 'confirm-details',
  RECOVERY_PHONE: 'recovery-phone',
  UPDATE_PASSWORD: 'update-password',
  CREATE_PASSWORD: 'create-password',
  MFA: 'mfa-phone',
  MFA_AGGRESSIVE: 'aggressive-2fa',
  OWNER_APP_2FA: 'owner-app-2fa',
  PREMIUM_USERS_2FA_ENFORCEMENT: 'premium-users-2fa-enforcement',
  CONFIRM_EXIT_ENFORCEMENT: 'confirm-exit-enforcement',
} as const;

export const TwoFactorAuthMethods = {
  TOTP: 'TOTP',
  Phone: 'Phone',
  Email: 'Email',
  Push: 'Push',
  Unused: 'Unused',
} as const;

export const TwoFactorAuthPhoneDeliveryMethods = {
  SMS: 'SMS',
  VOICE_CALL: 'VOICE_CALL',
} as const;

export const SEARCH_PARAMS = {
  TARGET_URL: 'targetUrl',
  EMAIL: 'email',
  DEFAULT_EMAIL: 'defaultEmail',
  POST_LOGIN_URL: 'postLogin',
  ORIGIN_URL: 'originUrl',
  REDIRECT_TO_URL: 'redirectTo',
  SHOW_WORKSPACE_SELECTOR: 'showWorkspaceSelector',
  POST_SIGNUP_URL: 'postSignUp',
  POST_LOGIN_OFFER: 'postLoginOffer',
  USER_DETAILS: 'userDetails',
  EMAIL_TOKEN: 'emailToken',
  GOOGLE_STATE: 'state',
  GOOGLE_ID_TOKEN: 'id_token',
  COLOR: 'color',
  USER_TOKEN: 'userToken',
  REFERRAL_INFO: 'referralInfo',
  INVITE_TOKEN: 'inviteToken',
  SEND_EMAIL: 'sendEmail',
  LOGIN_DIALOG_CONTEXT: 'loginDialogContext',
  ORIGIN_CONTEXT: 'originContext',
  FORCE_RENDER: 'forceRender',
  IS_STUDIO: 'studio',
} as const;

export type TwoFactorAuthMethod =
  | typeof TwoFactorAuthMethods.TOTP
  | typeof TwoFactorAuthMethods.Phone
  | typeof TwoFactorAuthMethods.Email
  | typeof TwoFactorAuthMethods.Push
  | typeof TwoFactorAuthMethods.Unused;

export type TwoFactorAuthPhoneDeliveryMethod =
  | typeof TwoFactorAuthPhoneDeliveryMethods.SMS
  | typeof TwoFactorAuthPhoneDeliveryMethods.VOICE_CALL;
export type IAUTH_METHODS_BY_MAIL = Values<typeof AUTH_METHODS_BY_MAIL>;

export const OFFERS_TO_OLD_ROUTES = {
  [POST_LOGIN_OFFERS.CONFIRM_DETAILS]: OLD_POST_LOGIN_ROUTES.CONFIRM_DETAILS,
  [POST_LOGIN_OFFERS.CONFIRM_EMAIL]: OLD_POST_LOGIN_ROUTES.CONFIRM_EMAIL,
  [POST_LOGIN_OFFERS.MFA]: OLD_POST_LOGIN_ROUTES.PHONE_2FA,
  [POST_LOGIN_OFFERS.MFA_AGGRESSIVE]:
    OLD_POST_LOGIN_ROUTES.PHONE_2FA_AGGRESSIVE,
  [POST_LOGIN_OFFERS.UPDATE_PASSWORD]: OLD_POST_LOGIN_ROUTES.CHANGE_PASSWORD,
  [POST_LOGIN_OFFERS.CREATE_PASSWORD]: OLD_POST_LOGIN_ROUTES.CREATE_PASSWORD,
  [POST_LOGIN_OFFERS.RECOVERY_PHONE]: OLD_POST_LOGIN_ROUTES.RECOVERY_PHONE,
  [POST_LOGIN_OFFERS.OWNER_APP_2FA]: OLD_POST_LOGIN_ROUTES.OWNER_APP_2FA,
  [POST_LOGIN_OFFERS.PREMIUM_USERS_2FA_ENFORCEMENT]:
    OLD_POST_LOGIN_ROUTES.PREMIUM_USERS_2FA_ENFORCEMENT,
  [POST_LOGIN_OFFERS.CONFIRM_EXIT_ENFORCEMENT]:
    OLD_POST_LOGIN_ROUTES.PREMIUM_USERS_CONFIRM_EXIT_ENFORCEMENT,
} as const;

export const OFFERS_TO_NEW_ROUTES = {
  [POST_LOGIN_OFFERS.CONFIRM_DETAILS]: NEW_POST_LOGIN_ROUTES.CONFIRM_DETAILS,
  [POST_LOGIN_OFFERS.CONFIRM_EMAIL]: NEW_POST_LOGIN_ROUTES.CONFIRM_EMAIL,
  [POST_LOGIN_OFFERS.MFA]: NEW_POST_LOGIN_ROUTES.PHONE_2FA,
  [POST_LOGIN_OFFERS.MFA_AGGRESSIVE]:
    NEW_POST_LOGIN_ROUTES.PHONE_2FA_AGGRESSIVE,
  [POST_LOGIN_OFFERS.UPDATE_PASSWORD]: NEW_POST_LOGIN_ROUTES.CHANGE_PASSWORD,
  [POST_LOGIN_OFFERS.CREATE_PASSWORD]: NEW_POST_LOGIN_ROUTES.CREATE_PASSWORD,
  [POST_LOGIN_OFFERS.RECOVERY_PHONE]: NEW_POST_LOGIN_ROUTES.RECOVERY_PHONE,
  [POST_LOGIN_OFFERS.OWNER_APP_2FA]: NEW_POST_LOGIN_ROUTES.OWNER_APP_2FA,
  [POST_LOGIN_OFFERS.PREMIUM_USERS_2FA_ENFORCEMENT]:
    NEW_POST_LOGIN_ROUTES.PREMIUM_USERS_2FA_ENFORCEMENT,
  [POST_LOGIN_OFFERS.CONFIRM_EXIT_ENFORCEMENT]:
    NEW_POST_LOGIN_ROUTES.PREMIUM_USERS_CONFIRM_EXIT_ENFORCEMENT,
} as const;

export const DEVICE_LOGIN_METHODS = {
  SIGNUP: 'SIGNUP',
  CONNECTION_SUCCESSFUL: 'CONNECTION_SUCCESSFUL',
  DISCONNECT_SUCCESSFUL: 'DISCONNECT_SUCCESSFUL',
} as const;

export const WIX_PARENT_ACCOUNT_HINT = 'wix';
export const TWOFA_PROCESS = '2fa-process';
export const twoFAProcessMethod = (method: TwoFactorAuthMethod) =>
  '2fa-process-' + method.toLocaleLowerCase();

export type CaptchaStatus = {
  [LOGIN_PAGE_CONTEXT]: boolean;
  [SIGNUP_PAGE_CONTEXT]: boolean;
  [GET_USER_ACCOUNTS_CONTEXT]: boolean;
};

export const EXPERIMENTS = {
  ACCOUNT_RECOVERY_NEW_API: 'specs.ident.accountRecoveryNewApi',
  USE_IAM_FOR_USERS_CALLS: 'specs.ident.useIAMPlatformForUsers',
  USE_IAM_FOR_USERS_LOGIN_CALLS: 'specs.ident.useIAMPlatformForLogin',
  SHOULD_DISPLAY_GOT_ON_SIGNUP_PAGE: 'specs.ident.signupGOT'
};

export const IFRMAE_EVENTS = {
  LOADED: 'loaded',
  AUTHENTICATION_FAILED: 'authentication_failed',
  AUTHENTICATION_COMPLETED: 'authentication_completed',
};
