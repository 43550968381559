import { makeObservable, observable } from 'mobx';
import {
  TwoFactorAuthMethod,
  TwoFactorAuthPhoneDeliveryMethod,
} from '../utils/constants';
import { ERROR_CODES, extractErrorKeyByErrorCode } from '../utils/errorHandler';
import { Constraint, constraints } from '../utils/validators';
import { FormField } from './formField';
import { RootStore } from './root';

export abstract class AuthenticateByCodeStore {
  protected readonly rootStore: RootStore;
  abstract verify(options: {
    code: string;
    rememberMe?: boolean;
  }): Promise<void>;
  abstract resendCode(options?: {
    phoneDeliveryMethod?: TwoFactorAuthPhoneDeliveryMethod;
  }): Promise<string | undefined>;
  onResendCodeClicked?(options?: {
    phoneDeliveryMethod?: TwoFactorAuthPhoneDeliveryMethod;
  }): Promise<string | undefined>;
  abstract setAuthParams(options: any): void;
  abstract enableAccess(): boolean;
  currentTwoFAMethod: TwoFactorAuthMethod;
  stateToken?: string;
  enabledTwoFAMethods?: TwoFactorAuthMethod[];
  twoFAHint?: string | null;
  codeField: FormField;
  SUBMIT_INTERACTION_NAME: string;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    const lengthErrorKey = 'enter_code.code_invalid_6_digits';
    const codeRules: Constraint[] = [
      [constraints.minimum(6), this.rootStore.i18n.t(lengthErrorKey)],
      [constraints.maximum(6), this.rootStore.i18n.t(lengthErrorKey)],
      [
        constraints.regex(/^[0-9]*$/),
        this.rootStore.i18n.t('enter_code.code_invalid_only_digits'),
      ],
      [constraints.required, this.rootStore.i18n.t('error.required_field')],
    ];
    this.codeField = new FormField('', codeRules);
    this.rootStore.navigationStore.registerHistoryChanges(() => this.clear());
    makeObservable(this, {
      codeField: observable,
    });
  }

  handleError(errorCode: string) {
    if (
      errorCode !== ERROR_CODES.GENERAL_ERROR_CODE &&
      this.SUBMIT_INTERACTION_NAME
    ) {
      this.rootStore.fedopsLogger.interactionEnded(
        this.SUBMIT_INTERACTION_NAME
      );
    }
    this.rootStore.modalModeHandlerStore.handleErrorReport(errorCode);
    const errorKey = extractErrorKeyByErrorCode(errorCode?.toString(), {
      context: this.currentTwoFAMethod.toLocaleLowerCase(),
    });
    this.codeField.addError(this.rootStore.i18n.t(errorKey));
    this.codeField.markFieldAsDirty();
  }

  clear() {
    this.codeField.clear();
  }
}
