import React, { FC, useCallback, useState } from 'react';
import { CustomInput, CustomInputProps } from '../CustomInput';
import { Box, BoxProps } from '@wix/design-system';
import { observer } from 'mobx-react';
import classnames from 'classnames';
import s from './InputField.scss';
import { dataHooks } from '../../dataHooks';
import { FormField } from '../../stores/formField';

export interface InputFieldProps extends CustomInputProps {
  formField?: FormField;
  marginBottom?: BoxProps['marginBottom'];
  marginTop?: BoxProps['marginTop'];
  wrapperClassName?: BoxProps['className'];
  disablePaste?: boolean;
}

export const InputField: FC<InputFieldProps> = observer(
  ({
    onBlur,
    onFocus,
    onChange,
    formField,
    border,
    disablePaste,
    marginBottom,
    marginTop,
    wrapperClassName,
    inputWithLabel,
    width,
    ...props
  }) => {
    const [isFocused, setIsFocused] = useState(false);

    const onInputBlur = useCallback(
      (e: React.FocusEvent<HTMLInputElement>) => {
        formField?.onBlur();
        onBlur && onBlur(e);
        setIsFocused(false);
      },
      [onBlur, formField]
    );

    const onInputFocus = useCallback(
      (e?: React.FocusEvent<HTMLInputElement>) => {
        formField?.onFocus();
        onFocus && e && onFocus(e);
        setIsFocused(true);
      },
      [onFocus, formField]
    );

    const onInputChange = useCallback(
      (e) => {
        formField?.onChange(e);
        onChange && onChange(e);
      },
      [onChange, formField]
    );

    const errorText = formField?.serverError || formField?.errorInfo;
    const showError =
      formField?.isDirty && formField?.isTouched && errorText && !isFocused;
    const status: 'warning' | 'error' = formField?.isWarning
      ? 'warning'
      : 'error';

    return (
      <Box
        width={width ?? '100%'}
        direction="vertical"
        marginTop={marginTop}
        marginBottom={marginBottom}
        paddingBottom={marginBottom}
        className={classnames(
          { [s.wrapperClassName]: inputWithLabel },
          wrapperClassName,
          s.statusMessage
        )}
      >
        <CustomInput
          dataHook={dataHooks.inputField.default}
          type={formField?.inputType}
          {...props}
          value={formField?.value}
          onBlur={(e) => onInputBlur(e)}
          onChange={(e) => onInputChange(e)}
          onFocus={(e?: React.FocusEvent<HTMLInputElement>) => onInputFocus(e)}
          status={showError ? status : undefined}
          statusMessage={errorText}
          onPaste={(e) => disablePaste && e.preventDefault()}
          border={border}
          inputWithLabel={inputWithLabel}
        />
      </Box>
    );
  }
);

export const InputFieldWithLabel: React.FC<
  Omit<InputFieldProps, 'inputType'>
> = (props) => <InputField {...props} inputWithLabel />;
