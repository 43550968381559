import React, { FC, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DialogHeader } from '../DialogHeader/DialogHeader';
import { Box, Image } from '@wix/design-system';
import ownerApp2FAExpired from '../../assets/images/ownerApp2FAExpired.svg?url';
import { OwnerApp2FAResendButton } from '../OwnerApp2FAResendButton/OwnerApp2FAResendButton';
import { MoreMethods } from '../OwnerApp2FA/OwnerApp2FAMoreMethods';
import { useTwoFactorAuth } from '../../stores/twoFactorAuth';
import { loginOwnerApp2FaErrorTimeoutPageView } from '@wix/bi-logger-identity-data/v2';

export const OwnerApp2FAExpired: FC = () => {
  const { t } = useTranslation();
  const { biLogger } = useTwoFactorAuth();

  const sendExpiredViewBi = useCallback(async () => {
    await biLogger.report(loginOwnerApp2FaErrorTimeoutPageView({}));
  }, [biLogger]);

  useEffect(() => {
    sendExpiredViewBi();
  }, [sendExpiredViewBi]);

  return (
    <>
      <DialogHeader>
        <DialogHeader.Title as="h3">
          {t('ownerAppTwoFactorAuth.expired.title')}
        </DialogHeader.Title>
        <DialogHeader.SubTitleSmall size="medium" weight="thin">
          {t('ownerAppTwoFactorAuth.expired.description')}
        </DialogHeader.SubTitleSmall>
        <Box
          direction="vertical"
          margin="58px 0 21px 0"
          align="center"
          gap="18px"
        >
          <Image src={ownerApp2FAExpired} transparent width="120px" />
          <OwnerApp2FAResendButton startWithTimer={false}/>
        </Box>
      </DialogHeader>
      <Box direction="vertical" align="center" gap={0} width="100%">
        <MoreMethods />
      </Box>
    </>
  );
};
