import {
  accountSettingsTwoFactorAuthenticationSetupSendCodeFailed,
  accountSettingsTwoFactorAuthenticationSetupSendCodeSuccess,
  twoFactorAuthenticationSetupConfirmCodeClick,
  twoFactorAuthenticationSetupSendCode,
} from '@wix/bi-logger-dash/v2';
import localeData from '@wix/locale-dataset-javascript';
import { Box } from '@wix/design-system';
import { constraints } from '../validate';
import { action, computed, makeObservable, observable } from 'mobx';
import { PremiumUsers2FaEnforcementStore } from '../premiumUsers2FaEnforcement';
import { VerifyCode } from '../verifyCode';
import { FormField } from '../utils/formField';
import { extractErrorCode } from '../utils';
import React from 'react';
import { Steps } from './types';
import { MultiFactorIdentifier } from '../multiFactorAuth';
import { AccountSettingsHttpApi } from '../../../services/accountSettingsApi';
import { BI_ORIGIN, EXPERIMENTS } from '../../constants';
import { VerificationState } from '../../../components/PostLogin/VerifyButton';

export interface CountryCodeOption {
  value: string | React.ReactNode;
  id: string;
  countryCode: string;
  alpha2Code?: string;
  icon?: React.ReactNode;
}

export enum UserChoice {
  PrimaryNumber = 0,
  NewNumber = 1,
}

const ERROR_TRANSLATIONS = {
  BAD_NUMBER: 'validation.phone.badNumber',
  BAD_CODE: 'validation.phone.badCode',
  WRONG_PASSWORD: 'validation.wrong.password',
  ENTER_CODE: 'add_phone_wizard.enter_code.errorInfo',
  PASSWORD_REQUIRED: 'add_phone_wizard.password.errorInfo',
};

export class AddPhoneStore extends VerifyCode {
  public confirmCode: FormField;
  public phoneNumber: FormField;
  public countryCode?: CountryCodeOption;
  public resendErrorMessage: string | null;
  public activeStep: Steps = Steps.AddPhoneStep;
  public userChoice: UserChoice = UserChoice.NewNumber;
  public countriesFilter: string = '';
  public countryCodesOptions: CountryCodeOption[] = [];
  public isSubmitted: boolean = false;
  public currentPassword: FormField;
  public verificationId: string;
  public verificationState: VerificationState = VerificationState.Unverified;
  public api: AccountSettingsHttpApi;

  constructor(
    public premiumUsers2FaEnforcementStore: PremiumUsers2FaEnforcementStore
  ) {
    super(premiumUsers2FaEnforcementStore);
    this.updateCountriesList();
    this.phoneNumber = new FormField(this.phone?.nationalNumber, [
      [
        constraints.required,
        this.premiumUsers2FaEnforcementStore.postLoginStore.i18n.t(
          ERROR_TRANSLATIONS.BAD_NUMBER
        ),
      ],
      [
        constraints.phone,
        this.premiumUsers2FaEnforcementStore.postLoginStore.i18n.t(
          ERROR_TRANSLATIONS.BAD_NUMBER
        ),
      ],
    ]);
    const errorMsg = this.premiumUsers2FaEnforcementStore.postLoginStore.i18n.t(
      ERROR_TRANSLATIONS.ENTER_CODE, {characterCount: 6}
    );
    this.confirmCode = new FormField(undefined, [
      [constraints.required, errorMsg],
      [constraints.minimum(6), errorMsg],
      [constraints.maximum(6), errorMsg],
    ]);
    this.currentPassword = new FormField(
      '',
      [
        [constraints.required, ERROR_TRANSLATIONS.PASSWORD_REQUIRED],
        [constraints.minimum(4), errorMsg],
      ],
      'password'
    );
    const confirmedCountryCode = this.phone?.countryCode;
    this.countryCode = this.countryCodesOptions?.find((option) => {
      // Finding the country code base on the suggested user alpha2Code code as we receive it from the server
      if (!confirmedCountryCode) {
        return (
          option.alpha2Code ===
          premiumUsers2FaEnforcementStore.postLoginStore.userPhone
            ?.requestAlpha2Code
        );
      }
      // If the user have phone saved already we use the saved data to find the right option.
      // In case the country code is +1 we fall back to US.
      // The reason we have this fallback is our server doesn't support country prop
      // but computed phone number (country code + phone number).
      if (confirmedCountryCode === '+1') {
        return option.alpha2Code === 'US';
      }
      return option.countryCode === confirmedCountryCode;
    });
    makeObservable(this, {
      confirmCode: observable,
      phoneNumber: observable,
      countryCode: observable,
      resendErrorMessage: observable,
      activeStep: observable,
      userChoice: observable,
      countriesFilter: observable,
      countryCodesOptions: observable,
      isSubmitted: observable,
      currentPassword: observable,
      onSendVerificationSms: action.bound,
      onResendVerificationSms: action.bound,
      onVerifyPhoneCode: action.bound,
      onSelectCountryCode: action.bound,
      onCloseModal: action.bound,
      getSelectedInternationalPhone: action,
      onSearchCountries: action,
      phone: computed,
      isPrimaryExist: computed,
      isRecoveryPhoneSet: computed,
      verificationState: observable,
      onBackToPreviousStep: action.bound,
      shouldDisableSubmit: computed,
    });
    this.api =
      this.premiumUsers2FaEnforcementStore.postLoginStore.accountSettingsApi;
  }

  private updateCountriesList() {
    const allCountries = localeData.getAllCountries().map((country) => {
      const flag = (
        <Box width="31px" height="28px" padding="2px 4px 4px 4px">
          <img
            src={`//static.parastorage.com/unpkg/@wix/wix-locale-data@0.0.3632/dist/images/svg-country-flags/${country.key}.svg`}
            alt={country.displayName}
          />
        </Box>
      );
      return {
        value: (
          <Box>
            {flag}
            {`${country.shortKey} ${country.dialCode}`}
          </Box>
        ),
        id: `${country.shortKey} ${country.dialCode}`,
        countryCode: country.dialCode,
        alpha2Code: country.shortKey,
        icon: <Box marginTop="3px">{flag}</Box>,
      };
    });
    if (this.countriesFilter?.length) {
      const lowerCaseFilter = this.countriesFilter.toLocaleLowerCase();
      this.countryCodesOptions = allCountries.filter((country) =>
        country.id?.toLowerCase().includes(lowerCaseFilter)
      );
    } else {
      this.countryCodesOptions = allCountries;
    }
  }

  public onRequestAddPhone() {
    this.isSubmitted = false;
    this.userChoice = this.isPrimaryExist
      ? UserChoice.PrimaryNumber
      : UserChoice.NewNumber;
    this.premiumUsers2FaEnforcementStore.postLoginStore.rootStore.navigationStore.navigate(
      this.premiumUsers2FaEnforcementStore.postLoginStore.postLoginRoutes
        .PREMIUM_USERS_ADD_PHONE
    );
  }

  public async onSendVerificationSms() {
    if (!this.phoneNumber.isValid && !this.isUsingPrimaryNumberFlow) {
      return;
    }

    this.premiumUsers2FaEnforcementStore.sendBiEvent(
      twoFactorAuthenticationSetupSendCode({
        action: 'send',
        mode: this.isRecoveryPhoneSet ? 'edit' : 'enable',
        phone_type: this.userChoice === 0 ? 'account phone' : 'new phone',
        origin: BI_ORIGIN.TWO_FA_ENFORCEMENT,
      })
    );
    try {
      this.isSubmitted = true;
      const response = await this.api.sendVerificationCode({
        phone: this.isUsingPrimaryNumberFlow
          ? {
              internationalNumber: this.getPrimary()?.internationalNumber,
            }
          : {
              countryCode: this.countryCode?.countryCode,
              nationalNumber: this.phoneNumber.value,
            },
      });

      this.verificationId = response?.data?.verificationId;

      this.premiumUsers2FaEnforcementStore.sendBiEvent(
        accountSettingsTwoFactorAuthenticationSetupSendCodeSuccess({
          action: 'send',
          mode: this.isRecoveryPhoneSet ? 'edit' : 'enable',
          origin: BI_ORIGIN.TWO_FA_ENFORCEMENT,
        })
      );
    } catch (error) {
      this.isSubmitted = false;
      this.premiumUsers2FaEnforcementStore.sendBiEvent(
        accountSettingsTwoFactorAuthenticationSetupSendCodeFailed({
          action: 'resend',
          mode: this.isRecoveryPhoneSet ? 'edit' : 'enable',
        })
      );
      const errorCode = extractErrorCode(error);
      if (errorCode === 9960) {
        this.currentPassword.addError(
          this.premiumUsers2FaEnforcementStore.postLoginStore.i18n.t(
            this.api.errorHandling(errorCode, ERROR_TRANSLATIONS.WRONG_PASSWORD)
          )
        );
        return;
      }
      this.phoneNumber.addError(
        this.premiumUsers2FaEnforcementStore.postLoginStore.i18n.t(
          this.api.errorHandling(errorCode, ERROR_TRANSLATIONS.BAD_NUMBER)
        )
      );
      return;
    }
    this.stepToConfirmCode();
  }

  public async onResendVerificationSms() {
    this.resendErrorMessage = null;
    this.premiumUsers2FaEnforcementStore.sendBiEvent(
      twoFactorAuthenticationSetupSendCode({
        action: 'resend',
        mode: this.isRecoveryPhoneSet ? 'edit' : 'enable',
        phone_type: this.userChoice === 0 ? 'account phone' : 'new phone',
        origin: BI_ORIGIN.TWO_FA_ENFORCEMENT,
      })
    );
    try {
      const response = await this.api.sendVerificationCode({
        phone: this.isUsingPrimaryNumberFlow
          ? this.getPrimary()
          : {
              countryCode: this.countryCode?.countryCode,
              nationalNumber: this.phoneNumber.value,
            },
      });
      this.verificationId = response?.data?.verificationId;
      this.premiumUsers2FaEnforcementStore.sendBiEvent(
        accountSettingsTwoFactorAuthenticationSetupSendCodeSuccess({
          action: 'resend',
          mode: this.isRecoveryPhoneSet ? 'edit' : 'enable',
          origin: BI_ORIGIN.TWO_FA_ENFORCEMENT,
        })
      );
    } catch (error) {
      this.premiumUsers2FaEnforcementStore.sendBiEvent(
        accountSettingsTwoFactorAuthenticationSetupSendCodeFailed({
          action: 'resend',
          mode: this.isRecoveryPhoneSet ? 'edit' : 'enable',
        })
      );
      const errorCode = extractErrorCode(error);
      this.resendErrorMessage =
        this.premiumUsers2FaEnforcementStore.postLoginStore.i18n.t(
          this.api.errorHandling(errorCode)
        );
      return;
    }
    this.resendSuccessfullyIndication = true;
  }

  onBackToPreviousStep() {
    this.isSubmitted = false;
    this.activeStep = Steps.AddPhoneStep;
  }

  public async onVerifyPhoneCode() {
    this.verificationState = VerificationState.Verifying;
    if (!this.confirmCode.isValid) {
      this.verificationState = VerificationState.Unverified;
      return;
    }
    const {
      countryCode: primaryCountryCode,
      nationalNumber: primaryNationalNumber,
      internationalNumber: primaryInternationalNumber,
    } = this.getPrimary() || {};
    const nationalNumber = this.isUsingPrimaryNumberFlow
      ? primaryNationalNumber
      : this.phoneNumber.value;
    const countryCode = this.isUsingPrimaryNumberFlow
      ? primaryCountryCode
      : this.countryCode?.countryCode;
    const internationalNumber = this.isUsingPrimaryNumberFlow
      ? primaryInternationalNumber
      : `${countryCode}${nationalNumber}`;
    try {
      await this.api[MultiFactorIdentifier.Phone].enable(
        {
          countryCode,
          nationalNumber,
          internationalNumber,
        },
        this.confirmCode.value,
        this.verificationId
      );
      this.premiumUsers2FaEnforcementStore.sendBiEvent(
        twoFactorAuthenticationSetupConfirmCodeClick({
          mode: 'enable',
          is_valid: true,
          origin: BI_ORIGIN.TWO_FA_ENFORCEMENT,
        })
      );
      await this.premiumUsers2FaEnforcementStore.multiFactorAuth.handle2faSettingsUpdate();
    } catch (error) {
      const errorCode = extractErrorCode(error);
      this.confirmCode.addError(
        this.premiumUsers2FaEnforcementStore.postLoginStore.i18n.t(
          this.api.errorHandling(errorCode, ERROR_TRANSLATIONS.BAD_CODE)
        )
      );
      this.premiumUsers2FaEnforcementStore.sendBiEvent(
        twoFactorAuthenticationSetupConfirmCodeClick({
          mode: 'enable',
          is_valid: false,
          origin: BI_ORIGIN.TWO_FA_ENFORCEMENT,
        })
      );
      this.verificationState = VerificationState.Unverified;
      return;
    }
    await this.premiumUsers2FaEnforcementStore.postLoginStore.fetchUserPhone();
    this.onCloseModal();
  }

  public onSelectCountryCode(option: any) {
    this.countryCode = option;
  }

  public onCloseModal() {
    this.userChoice = UserChoice.NewNumber;
    this.confirmCode.clear();
    this.phoneNumber.clear();
    if (this.isRecoveryPhoneSet) {
      this.phoneNumber.value = this.phone?.nationalNumber || '';
    }
    this.resendErrorMessage = null;
    this.verificationState = VerificationState.Verified;
    this.premiumUsers2FaEnforcementStore.goToOwnerAppOrDashboard(
      'close modal of addPhone'
    );
  }
  public getSelectedInternationalPhone(): string | undefined {
    return this.isUsingPrimaryNumberFlow
      ? this.getPrimary()?.internationalNumber
      : `${this.countryCode?.countryCode}${this.phoneNumber.value}`;
  }

  public onSearchCountries(searchValue: string) {
    this.countriesFilter = searchValue;
    this.countryCode = this.countryCodesOptions?.find(
      (c) => c.id === this.countriesFilter
    );
    this.updateCountriesList();
  }

  public stepToConfirmCode() {
    this.activeStep = Steps.ConfirmCodeStep;
  }
  get phone(): PhoneDTO | undefined {
    return this.premiumUsers2FaEnforcementStore.twoFASettings?.phoneMethod
      ?.phoneNumber;
  }

  public getPrimary() {
    return this.premiumUsers2FaEnforcementStore.postLoginStore.userPhone
      ?.Account?.Primary;
  }

  get isPrimaryExist(): boolean {
    return (
      this.premiumUsers2FaEnforcementStore.postLoginStore.userPhone?.Account
        ?.Primary?.verificationStatus === 'VERIFIED'
    );
  }

  get isUsingPrimaryNumberFlow(): boolean {
    return this.isPrimaryExist && this.userChoice === UserChoice.PrimaryNumber;
  }

  get isRecoveryPhoneSet(): boolean {
    return this.phone?.verificationStatus === 'VERIFIED';
  }

  get shouldStepupForEdit(): boolean {
    return this.premiumUsers2FaEnforcementStore.myAccountExperiments.enabled(
      EXPERIMENTS.SHOULD_STEPUP_EDIT_2FA
    );
  }

  get shouldDisableSubmit(): boolean {
    if (this.isSubmitted) {
      return true;
    }

    if (this.shouldStepupForEdit) {
      return (
        !this.phoneNumber.isValid && this.userChoice === UserChoice.NewNumber
      );
    }

    switch (this.userChoice) {
      case UserChoice.PrimaryNumber:
        return false;
      case UserChoice.NewNumber:
        return !this.phoneNumber.isValid || !this.phoneNumber.isDirty;
    }
  }
}
