import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Avatar, Box } from '@wix/design-system';
import { keys } from '../../translationsKeys';
import { AppleLoginButton } from '../AppleLoginButton/AppleLoginButton';
import { AppContextProvider } from '../AppLoader';
import { DialogFormContainer } from '../DialogContainer';
import { DialogHeader } from '../DialogHeader/DialogHeader';
import { FacebookLoginButton } from '../FacebookLoginButton/FacebookLoginButton';
import { GoogleLoginButton } from '../GoogleLoginButton/GoogleLoginButton';
import { PoliciesContainer } from '../PoliciesContainer';
import { SocialButtonsContainer } from '../SocialAuth/SocialAuth';
import { ThemedText, ThemedTextButton } from '../ThemedComponents';
import { observer } from 'mobx-react';
import s from './SocialOnlyDialog.scss';
import { ACCOUNT_RECOVERY_FORGOT_PASSWORD_URL } from '../../utils/constants';

const renderButtonByProvider = {
  google: () => <GoogleLoginButton />,
  facebook: () => <FacebookLoginButton />,
  apple: () => <AppleLoginButton />,
};

export const SocialOnlyDialog: React.FC = observer(() => {
  const {
    rootStore: {
      socialAuthStore: {
        socialConnectionsProviders,
        generateProviderName,
        showAlreadyExistsNote,
      },
      ssoStore: { ssoPageUrl },
      navigationStore,
      userDataStore,
      displayStore: { isMobile }
    },
  } = useContext(AppContextProvider);
  const { t } = useTranslation();
  const subTitleSuffix =
    !ssoPageUrl && socialConnectionsProviders.length < 2
      ? 'single'
      : 'multiple';
  const createPasswordWithEmailUrl = `${ACCOUNT_RECOVERY_FORGOT_PASSWORD_URL}?email=${encodeURIComponent(
    userDataStore.email,
  )}`;
  return (
    <Box direction="vertical" align="center" width="100%">
      <DialogFormContainer formClasses={[isMobile ? s.mobileDialogContainer : s.dialogContainer]}>
        <DialogHeader>
          <DialogHeader.Title as='h3'>
            {t(keys['socialOnlyDialog.title'])}
          </DialogHeader.Title>
          {showAlreadyExistsNote && (
            <DialogHeader.SubTitleSmall>
              {t(keys['socialOnlyDialog.subtitle.from_signup'])}
            </DialogHeader.SubTitleSmall>
          )}
          <DialogHeader.SubTitleSmall>
            {t(keys[`socialOnlyDialog.subtitle.${subTitleSuffix}`], {
              provider: generateProviderName(socialConnectionsProviders[0]),
            })}
          </DialogHeader.SubTitleSmall>
        </DialogHeader>
        <Box direction="vertical" align="center" gap={4}>
          <Box direction="vertical" align="center" gap={1}>
            <Avatar size="size48" />
            <ThemedText size="small" light secondary>
              {userDataStore.email}
            </ThemedText>
          </Box>
          <Box maxWidth="340px" width="100%">
            <SocialButtonsContainer alignItems="center">
              {socialConnectionsProviders.map((providerId) =>
                renderButtonByProvider[providerId]?.(),
              )}
            </SocialButtonsContainer>
          </Box>
          {ssoPageUrl && (
            <ThemedTextButton
              onClick={navigationStore.curryNavigate(ssoPageUrl)}
              size="small"
              underline="always"
              skin="standard"
            >
              {t(keys['emailStep.sso.button'])}
            </ThemedTextButton>
          )}
        </Box>
        <Box align="center" direction="vertical" paddingTop="20px" gap="5px">
          <ThemedText size="small" light secondary>
            {t(
              keys[
                `createPasswordForLostSoicalAccounts.title.${subTitleSuffix}`
              ],
              {
                provider: generateProviderName(socialConnectionsProviders[0]),
            })}
          </ThemedText>
          <ThemedTextButton
            underline="always"
            size="small"
            as="a"
            href={createPasswordWithEmailUrl}
            target="_blank"
          >
            {t(keys['createPassword.link'])}
          </ThemedTextButton>
        </Box>
      </DialogFormContainer>

      <PoliciesContainer>
        <PoliciesContainer.Minimal />
      </PoliciesContainer>
    </Box>
  );
});
