import { action, makeObservable, observable } from 'mobx';
import { AuthenticateByCodeStore } from './authenticateByCode';
import { RootStore } from './root';
import {
  loginTwoFactorAuthenticationSendCodeByPhoneClick,
  loginTwoFactorAuthenticationSendCodeByEmailClickSrc6Evid175,
  loginTwoFactorAuthenticationLoginWithAuthenticatorAppClick,
  loginClickOnBack,
} from '@wix/bi-logger-hls2/v2';
import { loginTwoFactorAuthenticationLoginWithOwnerAppClick } from '@wix/bi-logger-identity-data/v2';
import { TwoFactorAuthMethod, TwoFactorAuthMethods } from '../utils/constants';
import { ApprovalChallengeStatus } from '@wix/ambassador-identity-v1-verification/types';

export const TWO_FA_METHODS_MAP = {
  [TwoFactorAuthMethods.TOTP]: 'totp',
  [TwoFactorAuthMethods.Phone]: 'phone',
  [TwoFactorAuthMethods.Email]: 'email',
  [TwoFactorAuthMethods.Push]: 'push',
};
export class EnterCodeStore {
  private readonly rootStore: RootStore;

  rememberMe: boolean = true;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeObservable(this, {
      rememberMe: observable,
      onSwitchMethod: action,
      onToggleRememberThisDevice: action,
    });
  }

  onToggleRememberThisDevice() {
    this.rememberMe = !this.rememberMe;
  }

  async onSwitchMethod(
    newMethod: TwoFactorAuthMethod,
    authStore: AuthenticateByCodeStore,
  ) {
    const biEvent = {
      [TwoFactorAuthMethods.Email]:
        loginTwoFactorAuthenticationSendCodeByPhoneClick,
      [TwoFactorAuthMethods.Phone]:
        loginTwoFactorAuthenticationSendCodeByEmailClickSrc6Evid175,
      [TwoFactorAuthMethods.TOTP]:
        loginTwoFactorAuthenticationLoginWithAuthenticatorAppClick,
      [TwoFactorAuthMethods.Push]:
        loginTwoFactorAuthenticationLoginWithOwnerAppClick,
    };
    this.rootStore.biLogger.report(
      biEvent[newMethod]({ origin: authStore.currentTwoFAMethod }),
    );

    if (newMethod === TwoFactorAuthMethods.Push) {
      this.rootStore.twoFactorAuthStore.setTwoFactorAuthStatus(
        ApprovalChallengeStatus.PENDING_APPROVAL,
      );
    }
    authStore.stateToken = undefined; // when switch 2FA method we want to run the legacy code
    authStore.currentTwoFAMethod = newMethod;
    authStore.clear();
    await authStore.resendCode();
  }

  onBackButtonClicked(method: string) {
    this.rootStore.biLogger.report(
      loginClickOnBack({ step_name: 'verify_code', flow_type: method }),
    );
    this.rootStore.navigationStore.goBack();
  }
}
