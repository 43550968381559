import React, { useContext } from 'react';
import {
  CustomModalLayout,
  Box,
  Text,
  Layout,
  Cell,
  Image,
} from '@wix/design-system';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import phoneWithCodeSvg from '../../../../assets/images/phone-with-code.svg?url';
import { AppContextProvider } from '../../../AppLoader';
import { dataHooks } from '../../../../stores/premiumUsers2FaEnforcement/dataHooks';
import { SuperInput } from '../SuperInput';
import { BackTextButton } from '../OwnerApp/BackTextButton';
import s from '../modals.scss';
import VerifyButton from '../../VerifyButton';
import { EnforcementModal } from '../EnforcementModal';

export const AuthenticatorAppCodeModal: React.FC = observer(() => {
  const { t } = useTranslation();
  const {
    rootStore: {
      postLoginStore: {
        premiumUsers2FaEnforcementStore: { authenticatorAppModals: store },
      },
    },
  } = useContext(AppContextProvider);
  return (
    <EnforcementModal>
      <CustomModalLayout
        removeContentPadding
        width="600px"
        showHeaderDivider
        showFooterDivider
        primaryButtonProps={{
          disabled: !store.isSubmittable || !store.codeField.isDirty,
        }}
        primaryButtonOnClick={() => store.onCodeModalSubmit()}
        primaryButtonText={
          <VerifyButton verificationState={store.verificationState} />
        }
        title={t('multiFactorAuth.addAuthApp.codeModal.title')}
        sideActions={
          <BackTextButton goBack={store.onBackToQrModalModalSubmit} />
        }
        className={s.enforcementAuthAppModal}
      >
        <Box height="100%" width="100%" overflowY="clip">
          <Layout gap="0px">
            <Cell span={6}>
              <Box
                gap="12px"
                direction="vertical"
                width="222px"
                marginTop="24px"
                marginLeft="30px"
              >
                <Text>
                  {t(
                    'multiFactorAuth.addAuthApp.codeModal.content.description'
                  )}
                </Text>
                <SuperInput
                  autoFocus
                  maxLength={6}
                  onlyDigits
                  placeholder={t(
                    'multiFactorAuth.addAuthApp.codeModal.input.placeholder'
                  )}
                  field={store.codeField}
                  dataHooks={
                    dataHooks.multiFactorAuth.addAuthApp.codeModal.codeField
                  }
                />
                <Box marginTop="18px">
                  <Text size="small" secondary>
                    {t('multiFactorAuth.addAuthApp.codeModal.content.comment')}
                  </Text>
                </Box>
              </Box>
            </Cell>
            <Cell span={6}>
              <Image src={phoneWithCodeSvg} borderRadius="0px" height="100%" />
            </Cell>
          </Layout>
        </Box>
      </CustomModalLayout>
    </EnforcementModal>
  );
});
