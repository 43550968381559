import { observer } from 'mobx-react';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, CustomModalLayout, Input, Text } from '@wix/design-system';

import { dataHooks } from '../../../../stores/premiumUsers2FaEnforcement/dataHooks';
import { AppContextProvider } from '../../../AppLoader';
import { BackTextButton } from '../OwnerApp/BackTextButton';
import s from '../modals.scss';
import VerifyButton from '../../VerifyButton';
import { EnforcementModal } from '../EnforcementModal';
import { ResendVerificationCode } from '../../ResendVerificationCode';

export const ConfirmationCodeStepModal: React.FC = observer(() => {
  const { t } = useTranslation();

  useEffect(() => {
    store.resendSuccessfullyIndication = true;
  }, []);

  const {
    rootStore: {
      postLoginStore: {
        premiumUsers2FaEnforcementStore: { confirmEmail: store },
      },
    },
  } = useContext(AppContextProvider);

  return (
    <EnforcementModal>
      <CustomModalLayout
        showHeaderDivider
        primaryButtonText={
          <VerifyButton verificationState={store.verificationState} />
        }
        dataHook={dataHooks.confirmEmailModal.secondStep.confirmationCode.modal}
        primaryButtonOnClick={() => store.verifyConfirmationCode()}
        primaryButtonProps={{ disabled: !store.code.isValid }}
        title={store.titleSecondStep}
        width="560px"
        sideActions={<BackTextButton goBack={store.onBackToConfirmEmail} />}
        className={s.enforcementEmailModal}
      >
        <Box direction="vertical">
          <Text>
            {t('confirmEmailModalSecondStep.confirmationCode.content')}
          </Text>
          <Box>
            <Text weight="bold">{store.email}</Text>
          </Box>
          <Box width="160px" marginTop="6px" marginBottom={2}>
            <Input
              className={s.confirmCodeStepModal}
              size="medium"
              autocomplete="new-password"
              autoFocus
              placeholder={t(
                'confirmEmailModalSecondStep.confirmationCode.input.placeholder'
              )}
              value={store.code.value}
              onChange={store.code.onChange}
              onBlur={store.code.onBlur}
              status={
                !store.code.isValid && store.code.isTouched
                  ? Input.StatusError
                  : undefined
              }
              onEnterPressed={store.verifyConfirmationCode}
              statusMessage={store.code.errorInfo}
            />
          </Box>
          <ResendVerificationCode
            sendConfirmationCode={store.sendConfirmationCode}
            resendSuccessfullyIndication={store.resendSuccessfullyIndication}
            resendThrottleTimerValue={store.resendThrottleTimerValue}
            resendButtonSize="small"
            resendIndicationTranslationKey="code.resend.indication"
          />
        </Box>
      </CustomModalLayout>
    </EnforcementModal>
  );
});
