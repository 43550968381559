import { action, observable, makeObservable, computed, reaction } from 'mobx';
import { ssoLoginRequestClickToConnectWithSsoYellowButton } from '@wix/bi-logger-hls2/v2';
import { RootStore } from './root';
import { ApproveAndContinueModes } from '../components/ApproveAndContinue/approveAndContinueConstants';
import { ERROR_CODES, extractErrorKeyByErrorCode } from '../utils/errorHandler';
import { dataHooks } from '../dataHooks';
import { ROUTES } from '../routes';

export class ApproveAndContinueStore {
  public mode: ApproveAndContinueModes;
  public transParams: { [key: string]: string };
  private readonly rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.transParams = {};
    // TEMP - Default mode
    this.mode = ApproveAndContinueModes.SSO_MAIN;
    makeObservable(this, {
      mode: observable,
      transParams: observable,
      titleKey: computed,
      submitButtonKey: computed,
      contentKey: computed,
      descriptionKey: computed,
      showDescriptionSection: computed,
      secondaryLogoUrl: computed,
      errorCode: computed,
      errorKey: computed,
      shouldHideContent: computed,
      submit: action,
      setPageMode: action,
    });
    this.reactToEmailParamChanged();
    this.reactToAppLoaded();
  }

  private reactToEmailParamChanged() {
    reaction(
      () => {
        return this.rootStore.userDataStore?.email;
      },
      () =>
        (this.transParams = {
          ...this.transParams,
          email: this.rootStore.userDataStore.email,
        })
    );
  }

  private reactToAppLoaded() {
    reaction(
      () => {
        return this.rootStore.isLoaded;
      },
      () => this.rootStore.isLoaded && this.appLoadedCallback(),
      { fireImmediately: true }
    );
  }

  private appLoadedCallback() {
    this.setPageMode();
  }

  get titleKey(): string {
    switch (this.mode) {
      case ApproveAndContinueModes.SSO_OTHER_ACCOUNT_LOGGED_IN:
        return 'sso.otherAccountLoggedIn.title';
      case ApproveAndContinueModes.FORGOT_PASSWORD:
        return 'sso.forgotPassword.title';
      case ApproveAndContinueModes.FORGOT_EMAIL:
        return 'sso.forgotEmail.title';
      case ApproveAndContinueModes.SSO_MAIN:
      case ApproveAndContinueModes.SSO_MISSING_AUTH_SETTINGS:
      default:
        return 'sso.main.title';
    }
  }

  get submitButtonKey(): string {
    switch (this.mode) {
      case ApproveAndContinueModes.SSO_OTHER_ACCOUNT_LOGGED_IN:
        return 'sso.otherAccountLoggedIn.submitButton.text';
      case ApproveAndContinueModes.SSO_MAIN:
      default:
        return 'sso.main.submitButton.text';
    }
  }

  get contentKey(): string {
    switch (this.mode) {
      case ApproveAndContinueModes.SSO_OTHER_ACCOUNT_LOGGED_IN:
        return 'sso.otherAccountLoggedIn.content';
      case ApproveAndContinueModes.FORGOT_PASSWORD:
        return 'sso.forgotPassword.content';
      case ApproveAndContinueModes.FORGOT_EMAIL:
        return 'sso.forgotEmail.content';
      case ApproveAndContinueModes.SSO_MAIN:
      default:
        return 'sso.main.content';
    }
  }

  get descriptionKey(): string {
    switch (this.mode) {
      case ApproveAndContinueModes.FORGOT_PASSWORD:
        return 'sso.forgotPassword.description';
      case ApproveAndContinueModes.FORGOT_EMAIL:
        return 'sso.forgotEmail.description';
      case ApproveAndContinueModes.SSO_OTHER_ACCOUNT_LOGGED_IN:
      default:
        return 'sso.otherAccountLoggedIn.description';
    }
  }

  get showDescriptionSection(): boolean {
    return this.mode !== ApproveAndContinueModes.SSO_MAIN;
  }

  get secondaryLogoUrl(): string {
    if (this.isSsoMode) {
      return this.rootStore.ssoStore.accountImage;
    }
    return '';
  }

  get isSsoMode(): boolean {
    return this.rootStore.ssoStore.isSsoMode;
  }

  get errorCode(): string {
    return this.isSsoMode ? this.rootStore.ssoStore.errorCode : '';
  }

  get errorKey(): string {
    return extractErrorKeyByErrorCode(this.errorCode, {
      defaultError: 'errors.sso.technicalIssue',
    });
  }

  get showErrorSection(): boolean {
    return !!this.errorCode;
  }

  get titleDataHook(): string {
    return this.isSsoMode
      ? dataHooks.sso.mainDialog.title
      : dataHooks.approveAndContinueDialog.title;
  }

  get submitButtonDataHook(): string {
    return this.isSsoMode ? dataHooks.sso.mainDialog.submitButton : '';
  }

  get originParam(): string {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get('origin') || '';
  }

  get shouldHideContent(): boolean {
    return this.mode === ApproveAndContinueModes.SSO_MISSING_AUTH_SETTINGS;
  }

  public setPageMode() {
    const { currentLoggedInEmail } = this.rootStore.userDataStore;
    if (this.isSsoMode) {
      if (this.originParam) {
        switch (this.originParam) {
          case 'forgot-password':
            this.mode = ApproveAndContinueModes.FORGOT_PASSWORD;
            break;
          case 'forgot-email':
            this.mode = ApproveAndContinueModes.FORGOT_EMAIL;
            break;
          default:
            this.mode = ApproveAndContinueModes.SSO_MAIN;
        }
      } else if (
        this.rootStore.ssoStore.errorCode ===
        ERROR_CODES.SSO_ERROR_MISSING_OPENID_AUTH_METHOD
      ) {
        this.mode = ApproveAndContinueModes.SSO_MISSING_AUTH_SETTINGS;
      } else if (currentLoggedInEmail) {
        this.mode = ApproveAndContinueModes.SSO_OTHER_ACCOUNT_LOGGED_IN;
      } else {
        this.mode = ApproveAndContinueModes.SSO_MAIN;
      }
    }
    this.transParams = {
      ...this.transParams,
      ssoAccountName: this.rootStore.ssoStore.accountName,
      email: currentLoggedInEmail,
      ssoAccountEmail: this.rootStore.ssoStore.existingWixAccountEmail,
    };
  }

  public async submit() {
    if (this.isSsoMode) {
      this.rootStore.biLogger.report(
        ssoLoginRequestClickToConnectWithSsoYellowButton({
          account_id: this.rootStore.ssoStore.accountId,
        })
      );
      return this.rootStore.navigationStore.redirect(
        this.rootStore.ssoStore.ssoRedirectUrl,
        {
          searchParams: {
            inviteToken: this.rootStore.ssoStore.inviteToken,
          },
        }
      );
    }
  }
}
