import { action, makeObservable } from 'mobx';
import {
  wixSsoLoginPageClickOnContinueAfterEnteringEmail,
  wixSsoLoginPageClickOnBack,
} from '@wix/bi-logger-hls2/v2';
import { RootStore } from './root';
import { areAllUserAccountsBlocked, ERROR_CODES, extractErrorKeyByErrorCode } from '../utils/errorHandler';
import { ROUTES } from '../routes';
import { AccountsFilter } from './selectAccount';
import { EnterEmailStore } from './enterEmail';
import { AccountData } from '@wix/identity-fed-common';
import { SEARCH_PARAMS } from '../utils/constants';
import { CAPTCHA_ACTIONS } from './captcha';
import { concatParameterToUrl } from '../utils/general';
import { AUTH_TYPE } from '../components/BlockedAccount/authTypes';

// @ts-expect-error some unimplemented abstract methods
export class SsoEnterEmailStore extends EnterEmailStore {
  private captchaAdded: boolean = false;
  private shouldGetTargetUrlFromPostLoginUrlIfEmpty: boolean
  constructor(rootStore: RootStore) {
    super(rootStore);
    this.shouldGetTargetUrlFromPostLoginUrlIfEmpty = this.rootStore.experiments.enabled('specs.ident.ShouldGetTargetUrlFromPostLoginUrlIfEmpty');
    makeObservable(this, {
      submit: action,
    });
  }

  private handleSsoAccountNavigation(ssoAccounts: AccountData[]) {
    if (this.shouldGetTargetUrlFromPostLoginUrlIfEmpty && !this.rootStore.navigationStore.targetUrl) {
      this.rootStore.navigationStore.targetUrl = this.rootStore.ssoStore.getSsoTargetUrl();
    }
    if (ssoAccounts.length === 1 && ssoAccounts[0].accountSsoSettings?.ssoUrl) {
      const urlWithTargetParam = concatParameterToUrl(
        ssoAccounts[0].accountSsoSettings.ssoUrl,
        SEARCH_PARAMS.TARGET_URL,
        this.rootStore.navigationStore.targetUrl,
      );
      return this.rootStore.navigationStore.redirect(urlWithTargetParam);
    } else if (ssoAccounts.length > 1) {
      this.rootStore.selectAccountStore.setAndHandleFilter(AccountsFilter.SSO);
      this.rootStore.userDataStore.setEmail(this.emailField.value);
      return this.rootStore.navigationStore.navigate(ROUTES.SELECT_ACCOUNT);
    }
  }

  private handleSubmitErrorCode(errorCode: string) {
    this.captchaAdded =
      this.rootStore.captchaStore.createOrResetCaptchaIfNeeded(
        errorCode,
        this.captchaAdded,
      );

    if (this.rootStore.captchaStore.isCaptchaServerError(errorCode)) {
      return;
    }
    const errorKey = extractErrorKeyByErrorCode(errorCode);
    this.handleError(errorKey, errorCode);
  }

  onBackButtonClicked() {
    const { navigationStore, biLogger } = this.rootStore;
    biLogger.report(wixSsoLoginPageClickOnBack({}));
    navigationStore.goBack();
  }

  public async submit() {
    if (this.emailField.errorInfo || this.emailField.serverError) {
      return;
    }
    try {
      this.rootStore.biLogger.report(
        wixSsoLoginPageClickOnContinueAfterEnteringEmail({}),
      );
      const recaptchaParams =
        await this.rootStore.captchaStore.handleRecaptchaExecution({
          captchaAdded: this.captchaAdded,
          action: CAPTCHA_ACTIONS.GET_USER_ACCOUNTS,
        });
      const res = await this.rootStore.apiStore.getUserAccounts({
        email: this.emailField.value,
        ...recaptchaParams,
      });
      const ssoAccounts = res?.accountsData
        ? this.rootStore.ssoStore.filterSsoAccounts(res.accountsData)
        : [];
      if (ssoAccounts?.length) {
        const shouldNavigateToBlockedAccount = this.rootStore.experiments.enabled('specs.login.ShouldNavigateToBlockedAccount');
        if (shouldNavigateToBlockedAccount && areAllUserAccountsBlocked(ssoAccounts)) {
          return this.rootStore.navigationStore.navigateToBlockedAccount( {refferal_info: AUTH_TYPE.SSO} )
        }
        return this.handleSsoAccountNavigation(ssoAccounts);
      }

      this.handleError(
        extractErrorKeyByErrorCode(
          res?.accountsData?.length
            ? ERROR_CODES.SSO_ERROR_NOT_ASSOCIATED_WITH_SSO
            : ERROR_CODES.GENERAL_ERROR_CODE,
        ),
      );
    } catch (error: any) {
      const errorCode =
        error?.response?.status?.toString() === '404'
          ? ERROR_CODES.SSO_ERROR_USER_NOT_EXISTS
          : error?.response?.data?.errorCode ??
            error?.response?.data.error?.metadata?.['grpc-status-details']
              ?.applicationError?.code;
      this.handleSubmitErrorCode(errorCode);
    }
  }
}
